<template>
  <CHeader
    position="sticky"
    class="my-3 mx-2 mx-md-3 mx-lg-4"
    style="border-radius: 10px"
  >
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav style="font-family: 'Poppins'">
        <CNavItem>
          <CNavLink>
            <CDropdown variant="dropdown" auto-close="outside">
              <CDropdownToggle :caret="false"
                ><CIcon class="mx-2" icon="cil-search" size="lg" v-show="false"
              /></CDropdownToggle>
              <CDropdownMenu class="mt-4 search-dropdown">
                <CForm>
                  <CInputGroup
                    class="mx-3 search-dropdown-item"
                    style="background: #f7f7f7"
                  >
                    <CFormInput placeholder="Search" />
                    <CIcon
                      class="mx-2 my-auto"
                      icon="cil-search"
                      style="border-radius: 0px 0px 10px 10px"
                    />
                  </CInputGroup>
                </CForm>
              </CDropdownMenu>
            </CDropdown>
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink>
            <CDropdown variant="dropdown" auto-close="outside">
              <CDropdownToggle :caret="false"
                ><CIcon class="mx-2" icon="cil-bell" size="lg" v-show="false" />
              </CDropdownToggle>
              <CDropdownMenu class="mt-4 notif-dropdown">
                <CCard class="notif-dropdown-item">
                  <CCardBody class="px-2 px-md-3 px-lg-4">
                    <div class="fw-bold mb-4">Notification</div>
                    <CRow>
                      <CCol :xs="6" class="pe-1">
                        <CButton
                          size="sm"
                          style="font-size: smaller"
                          :class="
                            notifActive == 'tab1'
                              ? 'custom-label-notif-active'
                              : 'custom-label-notif-inactive'
                          "
                          @click="notifActive = 'tab1'"
                          >News Readings
                        </CButton>
                      </CCol>
                      <CCol :xs="6" class="ps-1">
                        <CButton
                          size="sm"
                          style="font-size: smaller"
                          :class="
                            notifActive == 'tab2'
                              ? 'custom-label-notif-active'
                              : 'custom-label-notif-inactive'
                          "
                          variant="outline"
                          @click="notifActive = 'tab2'"
                          >Most Recent Reports
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow class="my-4">
                      <CCol :xs="12" v-if="notifActive == 'tab1'">
                        <CCard
                          v-for="(notif, index) in notifNewRead"
                          :key="index"
                          class="my-3"
                          style="background: #f3f3f3; border: none"
                        >
                          <CCardBody class="mx-2">
                            <CRow>
                              <CCol
                                :xs="2"
                                class="bg-white"
                                style="border-radius: 10px"
                              >
                                <CIcon
                                  class="m-1"
                                  :icon="notif.icon"
                                  size="lg"
                                />
                              </CCol>
                              <CCol :xs="10">
                                <div class="fw-bold">{{ notif.title }}</div>
                                <div style="color: #9e9e9e">
                                  {{ notif.description }}
                                </div>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>
                      <CCol :xs="12" v-if="notifActive == 'tab2'">
                        <CCard
                          v-for="(notif, index) in notifMostRecent"
                          :key="index"
                          class="my-3"
                          style="background: #f3f3f3; border: none"
                        >
                          <CCardBody class="mx-2">
                            <CRow>
                              <CCol
                                :xs="2"
                                class="bg-white"
                                style="border-radius: 10px"
                              >
                                <CIcon
                                  class="m-1"
                                  :icon="notif.icon"
                                  size="lg"
                                />
                              </CCol>
                              <CCol :xs="10">
                                <div class="fw-bold">{{ notif.title }}</div>
                                <div style="color: #9e9e9e">
                                  {{ notif.description }}
                                </div>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CDropdownMenu>
            </CDropdown>
          </CNavLink>
        </CNavItem>
        <!--AppHeaderDropdownAccnt /-->
      </CHeaderNav>
    </CContainer>
    <!--CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer-->
  </CHeader>
</template>

<script>
//import AppBreadcrumb from './AppBreadcrumb'
//import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { ref } from 'vue'
export default {
  name: 'AppHeader',
  components: {
    //AppBreadcrumb,
    //AppHeaderDropdownAccnt,
  },
  setup() {
    const notifActive = ref('tab1')
    const notifNewRead = [
      {
        icon: 'cib-apple',
        title: 'Apple',
        description: 'Technology Company',
      },
      {
        icon: 'cib-facebook',
        title: 'Facebook',
        description: 'Technology Company',
      },
      {
        icon: 'cib-google',
        title: 'Google',
        description: 'Technology Company',
      },
    ]

    const notifMostRecent = [
      {
        icon: 'cil-home',
        title: 'Graphic Design',
        description: 'Building your portfolio in Notion: Part 1',
      },
      {
        icon: 'cil-home',
        title: 'Graphic Design',
        description: 'Building your portfolio in Notion: Part 1',
      },
    ]
    return {
      logo,
      notifActive,
      notifNewRead,
      notifMostRecent,
    }
  },
}
</script>

<style scoped>
@media only screen and (min-width: 300px) {
  .search-dropdown {
    width: 300px !important;
    left: -50px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .search-dropdown-item {
    width: 270px !important;
  }
  .notif-dropdown {
    width: 300px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .notif-dropdown-item {
    width: 300px !important;
    border: none;
  }
}

@media only screen and (min-width: 768px) {
  /*tablet*/
  .search-dropdown {
    width: 300px !important;
    left: -250px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .search-dropdown-item {
    width: 270px !important;
  }
  .notif-dropdown {
    width: 350px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .notif-dropdown-item {
    width: 350px !important;
  }
}

@media only screen and (min-width: 992px) {
  /*desktop*/
  .search-dropdown {
    width: 450px !important;
    left: -400px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .search-dropdown-item {
    width: 420px !important;
  }
  .notif-dropdown {
    width: 350px !important;
    border: none;
    box-shadow: 0px 2px 27px -12px rgba(66, 0, 255, 0.4);
  }
  .notif-dropdown-item {
    width: 350px !important;
  }
}
</style>
