<template>
  <div class="d-flex align-items-center">
    <CSpinner />
    <span class="ps-3">
      <b> {{ content }} </b>
    </span>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    content: {
      type: String,
      default: 'Finding data you are looking for.',
    },
  },
}
</script>
