import { defineStore } from 'pinia'
import client from '../helpers/HTTP'
// import moment from 'moment'
// import calculatePage from '../helpers/pagination'

export const useUserSetting = defineStore('userSetting', {
  state: () => {
    return {
      user: {},
      changePassword: {
        changePasswordResult: false,
        changePasswordWaiting: false,
        changePasswordAction: false,
        changePasswordError: null,
      },
      // pagination: {
      //   totalPage: 0,
      //   nextPage: false,
      //   prevPage: false,
      //   currentPage: 1,
      // },
      // pages: [],
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    submitChangePassword(payload = { oldPassword: '', newPassword: '' }) {
      this.changePassword.changePasswordWaiting = true
      this.changePassword.changePasswordResult = false
      this.changePassword.changePasswordAction = false
      this.changePassword.changePasswordError = ''
      client
        .post(`/client/auth/change-password`, payload)
        .then((response) => {
          this.changePassword.changePasswordError =
            response.data.error != null ? 'Please check your data again.' : null
          this.changePassword.changePasswordResult = response.data.success
          this.changePassword.changePasswordAction = true
          this.changePassword.changePasswordWaiting = false
          // else {
          //   if (
          //     !response.data.success &&
          //     response.data.error == 'Unauthorized user'
          //   ) {
          //     localStorage.clear
          //   }
          // }
        })
        .catch((err) => {
          console.log(err)
          this.changePasswordResult = false
          this.changePasswordWaiting = false
          this.changePasswordAction = true
          this.changePasswordError = 'Please check your data again.'
        })
    },
    reInit() {
      this.changePassword.changePasswordResult = false
      this.changePassword.changePasswordWaiting = false
      this.changePassword.changePasswordAction = false
      this.changePassword.changePasswordError = null
    },
  },
})

// export default createStore({
//   state: {
//     sidebarVisible: '',
//     sidebarUnfoldable: false,
//   },
//   mutations: {
//     toggleSidebar(state) {
//       state.sidebarVisible = !state.sidebarVisible
//     },
//     toggleUnfoldable(state) {
//       state.sidebarUnfoldable = !state.sidebarUnfoldable
//     },
//     updateSidebarVisible(state, payload) {
//       state.sidebarVisible = payload.value
//     },
//   },
//   actions: {},
//   modules: {},
// })
