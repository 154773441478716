import axios from 'axios'
export const login = (userCreds) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/client/auth/login`, userCreds)
      .then((result) => {
        console.log(result.data)
        if (
          result.data !== null &&
          result.data.error == null &&
          result.data.success == true
        ) {
          localStorage.setItem('token', result.data.data.jwt)
          localStorage.setItem(
            'state',
            result.data.data.user.company.subscription !== null,
          )
          if (result.data.data.user.verifiedAt != null) {
            localStorage.setItem('verified', result.data.data.user.verifiedAt)
          }
          resolve()
        } else {
          reject()
        }
      })
      .catch((e) => {
        console.log(e)
        reject()
      })
  })
}
export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('verified')
  localStorage.removeItem('state')
}
