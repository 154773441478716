<template>
  <div style="font-family: Poppins">
    <CModal backdrop="static" :visible="modalVisible" @close="changeModalState">
      <CModalBody
        ><CRow class="m-3 m-md-4 m-lg-5">
          <CForm class="needs-validation text-black" novalidate>
            <div class="fw-bold fs-6 mb-4">Change Password</div>
            <CRow
              class="mb-2"
              v-if="userSettingStore.changePassword.changePasswordError != null"
            >
              <CCol>
                <CAlert color="danger" variant="solid" @close="''">
                  {{ userSettingStore.changePassword.changePasswordError }}
                </CAlert>
              </CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol :xs="10" class="pe-1">
                <CFormInput
                  class="p-3"
                  :type="isOldPasswordVisible ? 'text' : 'password'"
                  placeholder="Current Password"
                  autocomplete="current-password"
                  :feedback="oldpassword.feedback"
                  :invalid="oldpassword.invalid"
                  v-model="oldpassword.input"
                />
              </CCol>
              <CCol :xs="2" class="ps-0">
                <div
                  class="fs-4 w-100 h-100 text-center pt-2 rounded"
                  style="background: #f7f7f7; height: 51px !important"
                  @click="isOldPasswordVisible = !isOldPasswordVisible"
                >
                  <EyeOutline
                    v-if="isOldPasswordVisible"
                    style="opacity: 0.5"
                  />
                  <EyeOffOutline v-else style="opacity: 0.5" />
                </div>
              </CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol :xs="10" class="pe-1">
                <CFormInput
                  class="p-3"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="New Password"
                  autocomplete="new-password"
                  :feedback="password.feedback"
                  :invalid="password.invalid"
                  v-model="password.input"
                />
              </CCol>
              <CCol :xs="2" class="ps-0">
                <div
                  class="fs-4 w-100 h-100 text-center pt-2 rounded"
                  style="background: #f7f7f7; height: 51px !important"
                  @click="isPasswordVisible = !isPasswordVisible"
                >
                  <EyeOutline v-if="isPasswordVisible" style="opacity: 0.5" />
                  <EyeOffOutline v-else style="opacity: 0.5" />
                </div>
              </CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol :xs="10" class="pe-1">
                <CFormInput
                  class="p-3"
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  placeholder="New Password Verification"
                  autocomplete="confirm-password"
                  :feedback="passwordConfirm.feedback"
                  :invalid="passwordConfirm.invalid"
                  v-model="passwordConfirm.input"
                />
              </CCol>
              <CCol :xs="2" class="ps-0">
                <div
                  class="fs-4 w-100 h-100 text-center pt-2 rounded"
                  style="background: #f7f7f7; height: 51px !important"
                  @click="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                >
                  <EyeOutline
                    v-if="isPasswordConfirmVisible"
                    style="opacity: 0.5"
                  />
                  <EyeOffOutline v-else style="opacity: 0.5" />
                </div>
              </CCol>
            </CRow>
            <CRow
              class="mb-2"
              v-if="
                userSettingStore.changePassword.changePasswordResult &&
                userSettingStore.changePassword.changePasswordAction
              "
            >
              <CCol>
                <CAlert color="success" variant="solid" @close="''">
                  Your password changed Successfully, you can close this form
                  now.
                </CAlert>
              </CCol>
            </CRow>
            <CRow
              class="mb-2"
              v-if="userSettingStore.changePassword.changePasswordWaiting"
            >
              <CCol>
                <Loading content="Please wait" />
              </CCol>
            </CRow>
            <CRow
              class="mb-2"
              v-if="
                (userSettingStore.changePassword.changePasswordAction &&
                  !userSettingStore.changePassword.changePasswordWaiting &&
                  userSettingStore.changePassword.changePasswordError !=
                    null) ||
                (!userSettingStore.changePassword.changePasswordAction &&
                  !userSettingStore.changePassword.changePasswordWaiting)
              "
            >
              <CCol>
                <div class="d-grid gap-2">
                  <CButton
                    color="primary"
                    class="px-4 text-white"
                    @click="submitChangePassword"
                  >
                    Change Password
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol>
                <div class="d-grid gap-2">
                  <CButton
                    color="danger"
                    class="px-4 text-white"
                    @click="changeModalState"
                  >
                    Close
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CRow>
      </CModalBody>
    </CModal>
    <CModal :visible="modalVisible2" @close="modalVisible2 = false">
      <CModalBody
        ><CRow class="mt-5">
          <CCol
            ><div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow class="mb-5">
          <CCol class="text-center">
            <h4 class="text-muted fw-bold">Submission Successfull</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center">
            <h5 class="fw-bold">
              Successfully created new user, your input has been
            </h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center">
            <CButton
              size="sm"
              color="secondary"
              class="text-white mt-3 py-2 px-5"
              @click="modalVisible2 = false"
              >BACK
            </CButton>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol class="text-end small text-muted text-center mx-5 mb-5">
            You will be redirected to home within 5 seconds, if not redirected
            you can press the button above.
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>

    <CModal :visible="modalVisible3" @close="modalVisible3 = false">
      <CModalBody
        ><CRow class="mt-5">
          <CCol
            ><div class="danger-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip-danger"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow class="mb-5">
          <CCol class="text-center">
            <h4 class="text-muted fw-bold">Submission Failed</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center">
            <h5 class="fw-bold">Failed to change password</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center">
            <CButton
              size="sm"
              color="secondary"
              class="text-white mt-3 py-2 px-5"
              @click="modalVisible3 = false"
              >BACK
            </CButton>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol class="text-end small text-muted text-center mx-5 mb-5">
            You will be redirected to home within 5 seconds, if not redirected
            you can press the button above.
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import EyeOutline from 'vue-material-design-icons/EyeOutline.vue'
import EyeOffOutline from 'vue-material-design-icons/EyeOffOutline.vue'
import { useModalChangePasswordStore } from '@/store'
import { useUserSetting } from '../../store/userSetting'
import Loading from '../../components/Loading.vue'
export default {
  name: 'Password',
  components: {
    EyeOutline,
    EyeOffOutline,
    Loading,
  },
  setup() {
    const userSettingStore = useUserSetting()
    const modalChangePasswordStore = useModalChangePasswordStore()
    const modalVisible = computed(() => modalChangePasswordStore.is_active)
    const changeModalState = () => {
      modalChangePasswordStore.$patch((state) => {
        state.is_active = false
        //window.location.reload()
        userSettingStore.reInit()
        console.log(state)
      })
    }

    // watch(
    //   () => changePasswordSuccess,
    //   (_, updated) => {
    //     if (updated == true) {
    //       setTimeout()
    //       changeModalState()
    //     }
    //   },
    // )

    const isPasswordVisible = ref(false)
    const isOldPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const modalVisible2 = ref(false)
    const modalVisible3 = ref(false)
    const oldpassword = ref({
      input: '',
      feedback: '',
      invalid: false,
    })
    const password = ref({
      input: '',
      feedback: '',
      invalid: false,
    })
    const passwordConfirm = ref({
      input: '',
      feedback: '',
      invalid: false,
    })
    const formPasswordValidation = () => {
      if (oldpassword.value.input == '') {
        oldpassword.value.invalid = true
        oldpassword.value.feedback = "Old password can't be empty"
      } else {
        oldpassword.value.invalid = false
        oldpassword.value.feedback = ''
      }
      if (password.value.input == '') {
        password.value.invalid = true
        password.value.feedback = "Password can't be empty"
      } else {
        if (oldpassword.value.input == password.value.input) {
          oldpassword.value.invalid = true
          oldpassword.value.feedback =
            'Old password and new password cannot be the same'
        }
        password.value.invalid = false
        password.value.feedback = ''
      }

      if (passwordConfirm.value.input == '') {
        passwordConfirm.value.invalid = true
        passwordConfirm.value.feedback = "Confirmation password can't be empty"
      } else {
        if (passwordConfirm.value.input !== password.value.input) {
          passwordConfirm.value.invalid = true
          passwordConfirm.value.feedback = "Confirmation password doesn't match"
        } else {
          passwordConfirm.value.invalid = false
          passwordConfirm.value.feedback = ''
          // modalVisible.value = false
          // modalVisible3.value = true
        }
      }
    }
    const submitChangePassword = () => {
      formPasswordValidation()
      if (
        !oldpassword.value.invalid &&
        !password.value.invalid &&
        !passwordConfirm.value.invalid
      ) {
        var payload = {
          oldPassword: oldpassword.value.input,
          newPassword: password.value.input,
        }
        userSettingStore.submitChangePassword(payload)
      }
    }
    return {
      modalVisible,
      modalVisible2,
      modalVisible3,
      isOldPasswordVisible,
      isPasswordVisible,
      isPasswordConfirmVisible,
      oldpassword,
      password,
      passwordConfirm,
      formPasswordValidation,
      modalChangePasswordStore,
      EyeOutline,
      EyeOffOutline,
      changeModalState,
      userSettingStore,
      submitChangePassword,
    }
  },
}
</script>
